$primary: #273E47 !default;
$secondary: #BD632F !default;
$tertiary: #D8973C !default;

$body-bg: lighten(#D8C99B, 20%) !default;
$maybe-usefull: #A4243B !default;

$input-btn-padding-y:         0.375rem !default;
$input-btn-padding-x:         0.9rem !default;

$border-radius:               0.25rem * 4 !default;
$border-radius-lg:            0.3rem * 4 !default;
$border-radius-sm:            0.2rem * 4 !default;
