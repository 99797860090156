.cleanliness-item {
  display: flex;
  border-radius: $border-radius;
  padding: $spacer;
  margin-bottom: $spacer;
  background: darken($body-bg, 2%);

  > * {
    padding: 0 $spacer / 2;
  }
}

.cleanliness-item__time {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $secondary;
  width: 50px;
  height: 50px;
  font-size: $font-size-sm;
  border-radius: 50%;
  color: #fff;
}

.cleanliness-item__content {
  flex: 1 0 auto;
}

.cleanliness-item__content-item {
  position: relative;
  margin-bottom: 0.5rem;
}

.cleanliness-item__puppy-name {
  font-weight: 700;
}

.cleanliness-item__delete {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
