.icon {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    &__circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #273E47;
    }

    img {
        position: relative;
        top: 5px;
        width: 80%;
    }
}
