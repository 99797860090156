@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

// vars
@import "variables/colors";
@import "variables/fonts";

// globals
@import "global/toplevel";

// bootstrap
@import '~bootstrap/scss/bootstrap';

// components
@import "components/heading";
@import "components/cleanliness";
