.heading-wrapper {
  display: flex;
  justify-content: center;

  .heading {
    position: relative;
    background: inherit;
    margin-bottom: 1rem;

    &:before, &:after {
      content: '';
      display: block;
      z-index: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 20px;
      background: $primary;
    }

    &:before {
      left: -25px;
    }

    &:after {
      right: -25px;
    }
  }
}
